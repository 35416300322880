<!-- 公司档案 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="container"
  >
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="persCenter-content">
      <div class="first">
        <span class="ziliao">公司资料</span>
        <ul>
          <li>
            <span class="tit">公司名称</span
            ><span class="name">{{ comDetails.name }}</span>
          </li>
          <li>
            <span class="tit">公司状态</span
            ><span class="name">{{
              comDetails.status == 1 ? "正常" : "停用"
            }}</span>
          </li>
          <li style="display: flex;align-items: center;">
            <span class="tit">公司地址</span>
            <span v-if="!editFlag" class="name"
              >{{ addName }}{{ comDetails.address }}</span
            >
            <!-- 编辑显示 -->
            <div v-else style="display: flex;align-items: center;">
              <el-cascader
                :props="props"
                v-model="params.address_id"
                class="common-screen-input"
                clearable
                placeholder="省市区"
              ></el-cascader>
              <el-input
                v-model="params.address"
                class="common-screen-input_55"
                placeholder="请输入具体地址"
              >
              </el-input>
            </div>
          </li>
          <li>
            <span class="tit">行业类型</span>
            <span v-if="!editFlag" class="name">{{
              comDetails.scale == 0
                ? "IT/通信/电子/互联网"
                : comDetails.scale == 1
                ? "金融业"
                : comDetails.scale == 2
                ? "商业服务"
                : comDetails.scale == 3
                ? "贸易/批发/零售/租赁业"
                : comDetails.scale == 4
                ? "文体教育/工艺美术"
                : comDetails.scale == 5
                ? "服务业"
                : comDetails.scale == 6
                ? "文化/传媒/娱乐/体育"
                : "--"
            }}</span>
            <el-select
              v-else
              v-model="params.scale"
              class="select"
              placeholder="请选择"
            >
              <el-option label="IT/通信/电子/互联网" :value="0"></el-option>
              <el-option label="金融业" :value="1"></el-option>
              <el-option label="商业服务" :value="2"></el-option>
              <el-option label="贸易/批发/零售/租赁业" :value="3"></el-option>
              <el-option label="文体教育/工艺美术" :value="4"></el-option>
              <el-option label="服务业" :value="5"></el-option>
              <el-option label="文化/传媒/娱乐/体育" :value="6"></el-option>
            </el-select>
          </li>
          <li>
            <span class="tit">人员规模</span>
            <span v-if="!editFlag" class="name">{{
              comDetails.industry == 0
                ? "0-50人"
                : comDetails.industry == 1
                ? "50-100人"
                : comDetails.industry == 2
                ? "100-500人"
                : comDetails.industry == 3
                ? "500-1000人"
                : comDetails.industry == 4
                ? "1000人以上"
                : "--"
            }}</span>
            <el-select
              v-else
              v-model="params.industry"
              class="select"
              placeholder="请选择"
            >
              <el-option label="0-50人" :value="0"></el-option>
              <el-option label="50-100人" :value="1"></el-option>
              <el-option label="100-500人" :value="2"></el-option>
              <el-option label="500-1000人" :value="3"></el-option>
              <el-option label="1000人以上" :value="4"></el-option>
            </el-select>
          </li>
          <li style="display: flex;">
            <span class="tit">附件</span>
            <div v-if="!editFlag" class="fileList-item">
              <div class="item-name">
                <common-picture
                  v-for="(item, i) in fileUrl"
                  :key="i"
                  :fileUrl="item"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.split('.')[item.split('.').length - 1]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="fileName[i]"
                ></common-picture>
              </div>
            </div>
            <!-- 编辑显示 -->
            <div v-else>
              <div
                v-for="(item, index) in fileList"
                :key="index"
                class="fileList-item"
              >
                <div class="item-name">
                  <common-picture
                    :fileUrl="item.fileUrl"
                    :fileType="
                      Config.fileType.imgType.includes(
                        item.fileUrl.split('.')[
                          item.fileUrl.split('.').length - 1
                        ]
                      )
                        ? 'imgText'
                        : 'file'
                    "
                    :fileName="item.fileName"
                  ></common-picture>
                </div>
                <div
                  @click="
                    () => {
                      deleteFlie(index);
                    }
                  "
                  class="deleteBtn"
                >
                  删除
                </div>
              </div>
              <div class="orderFileBtn">
                添加附件
                <input
                  class="orderFile-input"
                  type="file"
                  @input="
                    (e) => {
                      uploadFile(e);
                    }
                  "
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="second">
        <ul>
          <li>
            <span class="tit">创建人</span
            ><span class="name">{{ comDetails.create_by }}</span>
          </li>
          <li>
            <span class="tit">创建时间</span
            ><span class="name">{{ comDetails.create_time }}</span>
          </li>
          <li>
            <span class="tit">最后登录</span
            ><span class="name">{{ comDetails.lastLoginTime }}</span>
          </li>
          <li>
            <span class="tit">最后IP</span
            ><span class="name">{{ comDetails.ip }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer" style="text-align: left;">
      <el-button
        v-if="!editFlag"
        @click="
          () => {
            editCom();
          }
        "
        class="edit"
        style="background-color: #1890ff;color: #fff;"
        >修改</el-button
      >
      <el-button
        v-else
        @click="
          () => {
            updateForm();
          }
        "
        class="edit"
        style="background-color: #1890ff;color: #fff;"
        >确定</el-button
      >
      <el-button
        v-if="editFlag"
        @click="
          () => {
            editFlag = false
          }
        "
        class="edit"
        >取消</el-button
      >
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../../utils/index.js";
import { upload } from "../../service/upload.js";
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { details, update } from "../../service/login.js";
import { sysAreaDetail, sysArea } from "../../service/common.js";
import CommonPicture from "../../components/common/commonPicture.vue";

export default {
  name: "comCenter",
  components: { Breadcrumb, CommonPicture },
  data() {
    return {
      Config,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "公司档案", isLink: false }
      ],
      address: "",
      detailAddress: "",
      fileList: [],
      comDetails: {}, //公司详情
      addName: "",
      editFlag: false,
      loading: false,
      loading2: false,
      fileUrl: [], //显示---文件地址
      fileName: [], //显示---文件名
      params: {}, //修改档案新参数
      props: {
        //地区
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId;
          if (level == 0) {
            typeId = 0;
          } else {
            typeId = value;
          }
          let resData = (await sysArea({ id: typeId })).data;
          let nodes = resData.map((item) => ({
            value: item.id + "",
            label: item.name,
            leaf: level >= 2
          }));
          resolve(nodes);
        }
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getDetails();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getDetails() {
      this.loading = true;
      this.fileList = [];
      const { data } = await details();
      let addressId = data.address_id;
      // 地区
      if (data && addressId) {
        let id = addressId.split(",")[addressId.split(",").length - 1];
        const { data: res } = await sysAreaDetail({ id });
        this.addName = res;
      }
      // 处理图片
      let url = data.file_url;
      let name = data.file_name;
      let id = data.file_id;
      if (data && url && name && id) {
        let list1 = url.split(",");
        let list2 = name.split(",");
        let list3 = id.split(",");
        list1.forEach((item, i) => {
          this.fileList.push({
            fileUrl: item,
            fileName: list2[i],
            filedId: list3[i]
          });
        });
      }

      if (data && data.file_url) {
        this.fileUrl = data.file_url.split(",");
      }
      if (data && data.file_name) {
        this.fileName = data.file_name.split(",");
      }
      this.comDetails = data;
      this.loading = false;
    },
    editCom() {
      this.editFlag = true;
      this.params = {
        address: this.comDetails.address,
        // address_id: this.comDetails.address_id.split(','),
        industry: Number(this.comDetails.industry),
        scale: Number(this.comDetails.scale),
        filedId: [],
        fileName: [],
        filedUrl: []
      };
      if (this.comDetails && this.comDetails.address_id) {
        this.params.address_id = this.comDetails.address_id.split(",");
      }
    },
    // 上传文件
    async uploadFile(e) {
      this.loading = true;
      let fileList = [...this.fileList];
      let file = e.target.files[0];
      console.log(file, "?????????");
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.loading = false;
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.loading = false;
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      console.log(resData, "上传的图片");
      fileList.push({
        fileName: fileName,
        fileUrl: resData.filename,
        filedId: resData.id
      });
      this.fileList = fileList;
      e.target.value = "";
      this.loading = false;
    },
    deleteFlie(i) {
      let fileList = [...this.fileList];
      fileList = fileList.filter((item, index) => {
        return i != index;
      });
      this.fileList = fileList;
    },
    // 修改
    async updateForm() {
      this.loading = true;
      let fileList = this.fileList;
      if (fileList.length) {
        fileList.forEach((item) => {
          this.params.filedId.push(item.filedId);
          this.params.fileName.push(item.fileName);
          this.params.filedUrl.push(item.fileUrl);
        });
      }
      this.comDetails.companyId = this.comDetails.company_id;
      this.comDetails.companyName = this.comDetails.company_name;
      this.comDetails.createId = this.comDetails.create_id;
      this.comDetails.createTime = this.comDetails.create_time;
      this.comDetails.isDelete = this.comDetails.is_delete;
      this.comDetails.isDeleted = this.comDetails.is_deleted;
      this.comDetails.joinTime = this.comDetails.join_time;
      this.comDetails.nickName = this.comDetails.nick_name;
      this.comDetails.parentId = this.comDetails.parent_id;
      this.comDetails.passWord = this.comDetails.pass_word;
      this.comDetails.updateBy = this.comDetails.update_by;
      this.comDetails.updateTime = this.comDetails.update_time;
      this.comDetails.userDepartId = this.comDetails.user_depart_id;
      this.comDetails.userDepartName = this.comDetails.user_depart_name;
      let params = { ...this.comDetails };
      delete params.address_id;
      delete params.file_id;
      delete params.file_name;
      delete params.file_url;
      delete params.company_id;
      delete params.company_name;
      delete params.create_id;
      delete params.create_time;
      delete params.is_delete;
      delete params.is_deleted;
      delete params.join_time;
      delete params.nick_name;
      delete params.parent_id;
      delete params.pass_word;
      delete params.update_by;
      delete params.update_time;
      delete params.user_depart_id;
      delete params.user_depart_name;
      delete params.industry;
      delete params.scale;
      delete params.address;

      if (this.params && this.params.address_id) {
        params.addressId = this.params.address_id.join(",");
      }

      params.fileId = this.params.filedId.join(",");
      params.fileName = this.params.fileName.join(",");
      params.fileUrl = this.params.filedUrl.join(",");
      params.industry = this.params.industry;
      params.scale = this.params.scale;
      params.address = this.params.address;

      const res = await update(params);
      if (res.code == 200) {
        this.$message.success("修改成功");
      }
      this.loading = false;
      this.editFlag = false; //关闭修改
      this.getDetails();
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 16px;
  .breadcrumb-container {
    margin-left: 0;
  }
  .persCenter-content {
    .first {
      // height: 314px;
      text-align: left;
      border-radius: 8px;
      border: 1px solid rgba(238, 238, 238, 100);
      .ziliao {
        padding: 16px;
        display: block;
        border-bottom: 1px solid rgba(238, 238, 238, 100);
      }
      ul {
        padding: 16px;
        li {
          &:nth-child(-n + 5) {
            margin-bottom: 16px;
          }
        }
      }
    }
    .second {
      box-sizing: border-box;
      margin-top: 16px;
      margin-bottom: 32px;
      height: 161px;
      padding: 16px;
      text-align: left;
      border-radius: 8px;
      border: 1px solid rgba(238, 238, 238, 100);
      ul {
        li:nth-child(-n + 3) {
          margin-bottom: 16px;
        }
      }
    }
    .footer {
      /deep/.el-button,
      /deep/.el-button--default,
      /deep/.edit {
        width: 88px !important;
        height: 32px !important;
        background: #1890ff !important;
      }
    }
    .tit {
      display: inline-block;
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
      width: 100px;
    }
    .name {
      color: #999;
    }
    .orderFileBtn {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border: 1px solid rgba(220, 223, 230, 100);
      color: #666;
      background: #fff;
      text-align: center;
      font-size: 14px;
      position: relative;
      border-radius: 4px;
      display: inline-block;
      .orderFile-input {
        width: 100px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
      }
    }
  }
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    margin-bottom: 16px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
  // .fileList-item{
  //   display: inline-block;
  // }
  .add-ipt {
    height: 32px;
  }
  /deep/ .el-input {
    height: 32px !important;
    line-height: 30px;
  }
  .common-screen-input {
    margin-left: 0 !important;
  }
  .common-screen-input_55 {
    margin-left: 12px;
  }
}
</style>
